/* eslint-disable */
/**
 * @fileoverview
 *
 * 本文件词条由 `tea scan` 命令扫描生成，请勿手动编辑
 *
 * 国际化方案，请参考文档 http://tapd.oa.com/tcp_access/markdown_wikis/0#1020399462008817031
 */

/**
 * @type {import('@tea/app').I18NTranslation}
 */
var translation = {
  "k_002rp73": "静音",
  "k_002uuns": "回调",
  "k_002uzrd": "预览",
  "k_002vt4q": "描述",
  "k_003m86e": "重置",
  "k_003o97r": "标清",
  "k_003oe04": "销毁",
  "k_003p3qd": "超清",
  "k_003p7js": "高清",
  "k_003pb7f": "停止",
  "k_003qil7": "暂停",
  "k_003r4iv": "字幕",
  "k_003rhvm": "全屏",
  "k_003s38r": "更多",
  "k_00eum2f": "license 过期，请检查过期时间设置是否合理。",
  "k_00h11hy": "激活镜像功能，可以让视频画面镜像翻转，鼠标右键点击画面选择镜像。",
  "k_00p0tks": "播放参数 psign 校验失败。",
  "k_01errd5": "请求格式不合法，请检查请求格式。",
  "k_027yrcm": "播放器尺寸设置说明",
  "k_02o97ea": "多语言",
  "k_031umgo": "当前浏览器不支持 H264 编码",
  "k_039i528": "自定义 UI",
  "k_03aj50y": "错误码",
  "k_03ioh9m": "多实例",
  "k_03kvfyz": "视频解密时发生错误。",
  "k_0446i6j": "为了获取更好的产品功能及播放性能体验，建议结合腾讯 <1>云点播</1>和<3>云直播</3>使用。",
  "k_04hab2t": "可以通过 CSS 设置尺寸，支持像素和百分比等类型的值（如 width:\"100px\" 或 width:\"100%\" ）。",
  "k_05lmeyf": "如何获取云直播的直播流地址",
  "k_05pup04": "进度条标记说明",
  "k_06pffjb": "由于视频文件损坏或是该视频使用了您的浏览器不支持的功能，播放终止。",
  "k_07wojgz": "支持WebRTC、FLV、HLS的直播流地址，以及HLS、FLV、MP4等格式的点播播放地址。",
  "k_080cf2m": "仅使用腾讯云点播进行转码后的视频才可使用该功能，通过传入云点播账户 AppID 和云点播文件标识 FileID 进行播放。",
  "k_085uit9": "添加字幕可以在浏览器端如示例代码所示导入，对自适应码流也可以从云端添加字幕：即从云点播控制台导入字幕或调用 <1>云 API 关联字幕</1>。",
  "k_08x2l72": "开始/恢复",
  "k_09fuhzz": "未经转码的源视频在播放时有可能出现不兼容的情况，建议您使用转码后的视频进行播放。",
  "k_09pvs4c": "播放器尺寸",
  "k_0b468j9": "可在播放器初始化时开启清晰度切换提示开关，详见代码示例。",
  "k_0cqnwb5": "取消静音",
  "k_0dybyzp": "该功能仅支持桌面端浏览器。",
  "k_0em8mo0": "没有找到可以播放的自适应码流。",
  "k_0esiak9": "试看功能",
  "k_0fdrjph": "事件回调",
  "k_0flu757": "播放器没有检测到可用的视频地址。",
  "k_0fpz2wt": "FileID播放",
  "k_0h0ym3g": "获取视频数据超时。",
  "k_0hqiin4": "完整回调事件及其含义参见",
  "k_0iriiji": "试看时长不合法，试看时长要在视频时长范围内。",
  "k_0jhjcge": "当前浏览器支持 WebRTC 协议",
  "k_0kcdczi": "该功能不支持多端多浏览器互通，例如在某 PC 浏览器上观看后，无法在移动端浏览器或 PC 上另一个浏览器进行断点续播，若需相关能力可在本功能基础上自主开发额外的接口。",
  "k_0kg3huz": "如果上传的视频已生成封面图，优先使用生成的封面图，详情请看控制台 <1>【云点播】</1>-【音视频管理】。",
  "k_0lc49n9": "开启流程请参见 <1>Key 防盗链</1>。",
  "k_0lh7uux": "自适应码流",
  "k_0lq45lv": "播放HLS自适应码流文件时，播放清晰度将默认采用自动切换逻辑，此时播放器将根据当前带宽，动态选择最合适的码率播放。",
  "k_0lzvle5": "播放器没有检测到可以在当前播放器播放的视频数据，请对该视频进行转码操作。",
  "k_0mc1279": "如何获取直播地址？",
  "k_0mltw60": "网络错误导致视频下载中途失败。",
  "k_0mvjlb5": "缩略图预览-手动传入文件",
  "k_0njrcbd": "清晰度切换提示",
  "k_0ojwjt4": "播放 HLS 时出现内部异常。",
  "k_0pq59nj": "云端导入字幕后，播放器播放已关联字幕的文件时会自动加载并处理字幕文件，无需任何配置。",
  "k_0qi3j3c": "DASH 播放",
  "k_0qkqz0i": "参数 psign 即超级播放器签名，其具体含义请参见 <1>超级播放器签名</1>。",
  "k_0qt08o8": "如何获取点播地址？",
  "k_0r7dz8t": "由于网络问题造成加载视频失败。",
  "k_0sju0oc": "从控制台导入字幕路径：登录 <1>云点播控制台</1>，单击左侧导航栏的媒资管理，选择目标文件，进入管理即可添加字幕。 ",
  "k_0t5sx43": "清晰度切换提示说明",
  "k_0undtqk": "点播媒体数据接口请求超时。",
  "k_0uuad80": "HLS 规范的 Master Playlist 可以根据网络速度自适应码率播放，在视频下载过程中，如果网络速度满足下载高码率的 TS 分片时，播放器将切换播放高码率的 TS 分片，反之播放低码率的 TS 分片。移动端和桌面端大部分浏览器都支持该特性。",
  "k_0v27h08": "使用试看功能需要先开启 Key 防盗链，开启流程请参见 <1>Key 防盗链</1>。",
  "k_0v3fe2y": "自行生成缩略图文件与 VTT 文件，并将两个文件的 URL 传递给播放器，参考示例“缩略图预览 - 传入缩略图与 VTT 文件”",
  "k_0wcxqcj": "自定义提示文案说明",
  "k_0xwvtqx": "播放器播放的视频时长是 exper 参数指定的长度，与以往在播放端控制播放时长的试看功能不同，播放器不会获取完整的视频。",
  "k_0y96gt1": "当前浏览器支持 H264 编码",
  "k_0yiqkds": "自适应码流播放说明",
  "k_0ymre60": "当您想要自定义提示文案时，可以通过初始化参数 languages 设置指定的提示文案，详见以下列表",
  "k_0yzdvkm": "视频因格式不支持或者服务器或网络的问题无法加载。",
  "k_0zwy28a": "立即使用",
  "k_101ffsm": "动态水印移动范围为实际视频显示区域，如果视频自带黑边，播放器无法进行规避。",
  "k_10k6s3x": "通过实例化对象的 loadVideoByID(args) 方法，可以更换视频进行播放。",
  "k_11j7ygw": "AppID 不存在，请检查 AppID 是否正确。",
  "k_11wjj41": "缩略图预览说明",
  "k_125l369": "视频轮播",
  "k_1273spw": "倍速播放",
  "k_1275jtt": "视频播放",
  "k_127phqp": "断点续播",
  "k_136c92u": "在使用动态水印功能时，播放器对象的引用不能暴露到全局环境，否则动态水印可以轻易去除。",
  "k_13lwn3p": "统计信息",
  "k_13n45zz": "续播说明",
  "k_13n78u9": "试看说明",
  "k_13nasek": "镜像说明",
  "k_13ndw2l": "字幕说明",
  "k_13nnqvh": "倍速说明",
  "k_13npfig": "视频说明",
  "k_13pd56t": "统计数据",
  "k_13vzj4n": "视频切换",
  "k_14uixkl": "如何获取云点播的视频地址",
  "k_14wqpti": "缩略图预览-云端生成文件",
  "k_14zm3l5": "Key 防盗链",
  "k_1510klj": "产品文档",
  "k_1539km8": "提示文案",
  "k_153e7vr": "对应文案",
  "k_155n6pt": "视频镜像",
  "k_156asn6": "切换 fileID 播放说明",
  "k_157cxwb": "开启试看后播放器仍会显示视频原始时长（在 Chrome 和 Firefox 播放 HLS 格式的试看视频会显示试看时长）。",
  "k_15duorn": "贴片广告",
  "k_15kea92": "播放地址",
  "k_15lqs7v": "媒体文件不存在，请检查 fileID 是否正确。",
  "k_15w4ngo": "动态水印",
  "k_16ca8qr": "当前浏览器不支持 WebRTC 协议",
  "k_17hvv0p": "熟练运用 CSS 可以实现铺满全屏、容器自适应等效果。",
  "k_17w0lr4": "如果不设置宽高，播放器在获取到视频的分辨率后，将会以视频的分辨率设置播放器的显示尺寸，如果浏览器的可视区域尺寸小于视频分辨率，会造成播放器区域超出浏览器的可视区域，所以通常不建议这样做。最佳实践为通过 CSS 设置播放器的尺寸。",
  "k_18w5rr1": "动态水印不适合移动端场景，特别是劫持播放的场景。",
  "k_18xig6b": "生成的缩略图越多，进度条预览越精确，而缩略图越多，图片越大加载越慢，需要取舍平衡。",
  "k_1a5n7l2": "如果浏览器不支持倍速播放，播放器将不会显示倍速切换按钮。",
  "k_1c1zw6i": "可以给 video 标签设置 width 和 height 属性，width 和 height 的属性值是以像素计量的（如 width = \"100px\" 或 width = 100），不能设置百分比。",
  "k_1c95n4n": "pcfg 不唯一。",
  "k_1db6c0f": "不支持iOS端播放",
  "k_1dmqlhb": "输入appID",
  "k_1ehao7o": "输入视频地址",
  "k_1fx25n4": "输入psign",
  "k_1ggmljx": "URL播放",
  "k_1h5gx2p": "支持WebRTC、FLV、HLS的直播流地址，以及HLS、FLV、MP4等格式的点播播放地址",
  "k_1hhhesc": "Web端超级播放器体验",
  "k_1hsoaa7": "统计信息说明",
  "k_1kb6rai": "通过服务端 API 生成视频的缩略图与 VTT 文件，相关文档可参阅 <1>截图 - 雪碧图</1>",
  "k_1kj1wz2": "进度条标记",
  "k_1kq6bhg": "点播媒体数据接口返回异常数据。",
  "k_1lf5ssz": "Key 防盗链说明",
  "k_1lw5sk2": "可以通过屏蔽全屏按钮，规避部分全屏后被劫持导致水印失效的情况。",
  "k_1nbuhzq": "点播媒体数据接口没有返回数据。",
  "k_1o0bdon": "该功能通过 localStorage 存储播放时间点，浏览器需支持该特性。",
  "k_1ogc4lc": "广告贴图说明",
  "k_1ou4ycy": "动态水印说明",
  "k_1oz0i1l": "视频数据加载过程中被中断。",
  "k_1prmhzi": "PC 端播放器预览",
  "k_1prw0a6": "移动端播放器预览",
  "k_1psqlng": "播放器功能",
  "k_1q1rmpf": "多分辨率切换",
  "k_1qrljj0": "内部错误。",
  "k_1qsmzip": "其他错误。",
  "k_1rafeu0": "开启右键菜单打开视频统计信息选项。",
  "k_1s10v9w": "媒体服务器错误。",
  "k_1tdf4t0": "试看时长是根据视频关键帧进行裁剪，实际截取的试看时长可能会比设定值少。",
  "k_1ujmxqw": "在浏览器劫持视频播放的情况下，该功能无法使用。",
  "k_1v8uttc": "没带防盗链检测。",
  "k_1v9j74o": "输入fileID",
  "k_1wto79j": "请输入播放地址",
  "k_1xnkszg": "视频解码时发生错误。",
  "k_1y09ps1": "由于部分浏览器没有提供相应的接口和不支持 MSE，这些浏览器无法手动选择特定的清晰度，也不会显示切换清晰度的选项。",
  "k_1ycm4vz": "事件回调说明",
  "k_1z0jw22": "在不支持 MSE 的浏览器环境，不支持通过控制台导入字幕的方式。"
};

module.exports = { translation: translation };
